/* Borrowed from Bootstrap */
html {
  font-size: 16px; }

div#skiplinks a,
div#skiplinks a:link,
div#skiplinks a:hover,
div#skiplinks a:visited,
div#skiplinks a:active,
div#skiplinks p {
  position: absolute;
  left: 0;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: #ccc; }

.bx-wrapper {
  position: relative;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  box-shadow: none; }
  .bx-wrapper img {
    max-width: 100%; }

.bxslider {
  margin: 0;
  padding: 0; }

ul.bxslider {
  list-style: none; }

.bx-viewport {
  -webkit-transform: translatez(0); }

div.social > a {
  background-color: #f3a36f;
  color: #fff;
  display: inline-block;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  margin-right: 0.5em;
  border-radius: 50%;
  transition: background-color ease-in-out 0.1s; }
  div.social > a:last-child {
    margin-right: 0; }
  div.social > a:hover, div.social > a:active {
    background-color: #f57f32; }

article#page-content {
  padding: 48px 0; }
  @media (max-width: 991px) {
    article#page-content h1:first-child {
      margin-top: 1em; } }

header#page-header {
  text-align: left;
  background-color: #444;
  color: #fff;
  padding: 20px 0;
  font-family: "Montserrat", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center; }
  header#page-header a {
    color: #fff; }
    header#page-header a.btn-header {
      max-width: 280px;
      margin-bottom: 1em; }
      @media (max-width: 767px) {
        header#page-header a.btn-header {
          margin-left: auto;
          margin-right: auto; } }
    header#page-header a.btn-throb {
      animation: throb 2s ease-in-out infinite; }
  header#page-header.page-header-home {
    background-image: url(/site/img/header/home.jpg); }
    @media (min-width: 992px) and (max-width: 1199px) {
      header#page-header.page-header-home {
        height: 840px; } }
    @media (min-width: 1200px) {
      header#page-header.page-header-home {
        height: 780px; } }
  header#page-header.page-header-packages {
    background-image: url(/site/img/header/packages.jpg); }
  header#page-header #page-header-logo {
    margin: 0 auto;
    display: block; }
  header#page-header #page-header-phone > a {
    border-bottom: 0;
    font-size: 15px;
    letter-spacing: 1.5px; }
  header#page-header .header-social {
    margin-bottom: 0.3333em;
    font-size: 18px;
    text-align: center; }
  header#page-header > .container-fluid > .row > div {
    margin-bottom: 30px; }

div#navigation-header {
  z-index: 200; }
  div#navigation-header .hamburger {
    outline: 0 !important; }
  div#navigation-header.expanded {
    position: fixed;
    top: 20px;
    right: 0;
    width: 280px; }
  @media (min-width: 768px) {
    div#navigation-header {
      max-width: 280px;
      float: right; } }

nav#toggle-navigation {
  color: #fff; }

nav#page-navigation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  background-color: #444;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.33);
  z-index: 100;
  transform: translateX(315px);
  will-change: transform;
  transition: transform ease-in-out 0.25s; }
  nav#page-navigation.visible {
    transform: translateX(0); }
  nav#page-navigation ul {
    list-style: none;
    margin: 130px 0 0 0;
    padding: 0; }
    nav#page-navigation ul > li {
      margin: 15px auto; }
      nav#page-navigation ul > li > a {
        letter-spacing: 3px;
        border-bottom: 0;
        padding: 6px 0;
        margin: 15px auto;
        position: relative;
        color: #fff;
        text-transform: uppercase;
        display: block; }
        nav#page-navigation ul > li > a:hover, nav#page-navigation ul > li > a:focus {
          color: #f57f32;
          background-color: transparent; }
        nav#page-navigation ul > li > a > span {
          display: inline-block; }
      nav#page-navigation ul > li.external > a {
        color: #f57f32; }
      nav#page-navigation ul > li.active > a > span {
        border-bottom: 3px solid #f57f32;
        padding: 0 10px; }

aside#featured-properties > .bxslider {
  display: none; }

aside#featured-properties .bx-viewport {
  margin: 40px 0 20px; }

aside#featured-properties .bx-pager-item {
  display: inline-block;
  margin: 0 4px; }
  aside#featured-properties .bx-pager-item > a {
    text-indent: -999vw;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #bbbbbb; }
    aside#featured-properties .bx-pager-item > a.active {
      background-color: #bbbbbb; }

.featured-item {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-bottom: 2em;
  border-bottom: 0; }
  .featured-item .item-photo {
    height: 250px;
    background-size: cover;
    background-position: center center;
    margin-bottom: 1em;
    position: relative;
    overflow: hidden; }
    .featured-item .item-photo > .item-description {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 20px;
      font-size: 10px;
      letter-spacing: 1px;
      transform: translateY(100%);
      transition: transform ease-in-out 0.25s;
      will-change: transform; }
    .featured-item .item-photo > div.item-attributes {
      background-color: rgba(245, 127, 50, 0.8);
      color: #fff;
      position: absolute;
      top: 20px;
      right: 0; }
      .featured-item .item-photo > div.item-attributes > .item-attribute-icons > div {
        display: block;
        margin: 6px 12px;
        font-size: 10px; }
      .featured-item .item-photo > div.item-attributes i {
        display: inline-block;
        font-size: 1.3333em;
        margin-left: 0.3333em; }
  .featured-item h4 {
    color: #f57f32;
    margin: 0;
    font-size: 18px;
    letter-spacing: 1px; }
  .featured-item p {
    margin: 0;
    font-weight: bold;
    color: #444;
    letter-spacing: 1px; }
  .featured-item:hover .item-photo > .item-description {
    transform: translateY(0); }

aside#home-parallax {
  margin: 60px 0;
  height: 400px;
  overflow-y: hidden; }
  aside#home-parallax #home-parallax-photo {
    background-image: url(/site/img/home-content.jpg);
    background-size: cover;
    background-position: center center;
    height: 100vh;
    transform: translateY(-50vh);
    will-change: transform; }
  @media (max-width: 767px) {
    aside#home-parallax {
      height: 300px; } }

aside#property-slider {
  position: relative; }
  @media (min-width: 1200px) {
    aside#property-slider:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
      left: 0;
      pointer-events: none;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.9) 100%); } }
  aside#property-slider .slide {
    cursor: pointer;
    min-height: 400px;
    background-size: cover;
    background-position: center center; }
    @media (max-width: 767px) {
      aside#property-slider .slide {
        min-height: 280px; } }
  aside#property-slider .bxslider {
    display: none; }
  aside#property-slider .bx-wrapper .bxslider {
    display: block; }
  aside#property-slider .bx-controls-direction {
    font-family: FontAwesome; }
    aside#property-slider .bx-controls-direction > a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: 0;
      background-color: #fff;
      border-radius: 50%;
      color: #444;
      text-align: center;
      width: 1.8em;
      height: 1.8em;
      line-height: 1.7em;
      z-index: 10; }
      aside#property-slider .bx-controls-direction > a.bx-prev {
        left: 1em; }
      aside#property-slider .bx-controls-direction > a.bx-next {
        right: 1em; }

nav#search-navigation {
  background-color: #eee; }
  nav#search-navigation .navbar-toggle {
    border: 0; }
    nav#search-navigation .navbar-toggle .icon-bar {
      background-color: #444; }
  nav#search-navigation .navbar {
    margin: 0;
    border: 0; }
    nav#search-navigation .navbar .navbar-nav > li > a {
      border-top: 3px solid #eee;
      border-bottom: 3px solid #eee;
      color: #444;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      padding-left: 25px;
      padding-right: 25px; }
    nav#search-navigation .navbar .navbar-nav > li.active > a {
      border-top: 3px solid #f57f32;
      border-bottom: 3px solid #f57f32; }
    @media (min-width: 768px) {
      nav#search-navigation .navbar .navbar-nav > li {
        border-right: 1px solid #e1e1e1; }
        nav#search-navigation .navbar .navbar-nav > li:first-child {
          border-left: 1px solid #e1e1e1; } }
  nav#search-navigation i {
    color: #f57f32; }
  @media (min-width: 768px) {
    nav#search-navigation .navbar-collapse {
      padding-left: 0;
      padding-right: 0; }
    nav#search-navigation .navbar-form {
      margin-top: 7px;
      margin-bottom: 7px; } }

aside.page-search-properties {
  background-color: #f57f32;
  color: #fff;
  padding: 45px 30px 30px;
  margin-top: 45px; }
  aside.page-search-properties label[for=ShowSold] {
    margin: 1em 0;
    font-size: 14px; }
  @media (max-width: 991px) {
    aside.page-search-properties .row.form-group {
      margin-bottom: -8px; }
      aside.page-search-properties .row.form-group > div {
        margin-bottom: 10px; } }

.search-list-page {
  display: none; }
  .search-list-page.active {
    display: block; }

.property-item-wrapper {
  margin-bottom: 1.5em;
  box-shadow: 0 0 10px 0 rgba(68, 68, 68, 0.1);
  border: 1px solid #eee;
  display: block;
  transition: box-shadow ease-in 0.5s;
  position: relative;
  overflow: hidden; }
  .property-item-wrapper:hover, .property-item-wrapper:active, .property-item-wrapper:focus, .property-item-wrapper:hover:active {
    border-bottom-color: #eee;
    box-shadow: 0 0 8px 2px rgba(68, 68, 68, 0.15);
    outline: 0; }
    .property-item-wrapper:hover .item-view-more, .property-item-wrapper:active .item-view-more, .property-item-wrapper:focus .item-view-more, .property-item-wrapper:hover:active .item-view-more {
      border: 0;
      background-color: #f57f32;
      color: #fff; }
  .property-item-wrapper div.item-actions {
    position: absolute;
    bottom: 0;
    width: 100%; }
    @media (max-width: 991px) {
      .property-item-wrapper div.item-actions {
        display: none; } }
  .property-item-wrapper .item-view-more {
    position: absolute;
    background-color: #eee;
    width: auto;
    display: inline-block;
    padding-right: 70px;
    padding-left: 30px;
    border: 0;
    bottom: 0;
    right: 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px; }
    .property-item-wrapper .item-view-more > .icon {
      width: 40px;
      background-color: #f57f32;
      color: #fff; }
  @media (min-width: 992px) {
    .property-item-wrapper div.item-social {
      left: 44%;
      position: absolute;
      bottom: 0; } }

div.item-social {
  font-size: 12px; }
  div.item-social .jssocials-share-link {
    border-radius: 50%;
    border: 0; }

.property-item .row > div {
  padding: 0;
  position: static; }

.property-item h3 {
  font-size: 16px;
  width: 75%;
  margin: 1.5em auto 1em; }

@media (min-width: 768px) and (max-width: 991px) {
  .property-item .item-details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

.property-item p.item-description {
  margin: 0 auto;
  width: 75%;
  font-size: 11px;
  text-transform: uppercase;
  color: #444;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  margin-bottom: 60px; }
  @media (max-width: 1199px) {
    .property-item p.item-description {
      display: none; } }

.property-item .item-banner {
  position: absolute;
  top: 100px;
  left: -2.3333em;
  width: 180px;
  background-color: rgba(245, 127, 50, 0.9);
  color: #fff;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 0.6667em 0;
  transform: rotate(-45deg);
  transform-origin: top left; }

div.item-attributes {
  margin: 0 auto 1em;
  color: #444; }
  div.item-attributes > div {
    vertical-align: middle;
    padding-right: 20px;
    border-right: 1px solid #eee;
    margin-right: 20px; }
    div.item-attributes > div.item-attribute-icons {
      font-size: 10px; }
      div.item-attributes > div.item-attribute-icons > div {
        width: 3em; }
    div.item-attributes > div.item-attribute-price {
      font-weight: bold;
      letter-spacing: 1px; }
    div.item-attributes > div:last-child {
      padding-right: 0;
      border-right: 0;
      margin-right: 0; }
  div.item-attributes div {
    display: inline-block; }
  div.item-attributes i {
    display: block;
    font-size: 1.8em; }
    div.item-attributes i.fa-bath {
      margin-bottom: 0.15em; }
    div.item-attributes i.vs-sofa {
      margin-bottom: 0.075em; }

.gm-style-iw .property-item {
  margin-bottom: 0;
  box-shadow: none;
  border: 0; }

section#property-details .bullet-row {
  font-family: "Montserrat", sans-serif;
  color: #f57f32;
  font-size: 0.875em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal; }
  section#property-details .bullet-row > li {
    margin-bottom: 1.5em;
    line-height: 1.25; }
    @media (max-width: 991px) {
      section#property-details .bullet-row > li {
        margin-bottom: 0.9em; } }

section#property-details .item-attributes {
  margin-bottom: 50px; }

#toggle-sections {
  margin-bottom: 3em; }

section#property-epc .img-thumbnail {
  margin-bottom: 1.75em; }

section#home-valuation {
  margin-bottom: 2.5em;
  animation: flyin 2s ease-in-out; }
  section#home-valuation h2 {
    font-size: 28px;
    line-height: 1.3333;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-bottom: 0.5em;
    font-weight: bold;
    margin-top: 0; }

section#home-sales {
  margin: 0;
  text-align: center;
  font-family: "Montserrat", sans-serif; }
  section#home-sales h2 {
    margin-top: 30px;
    font-size: 28px;
    line-height: 1.3333;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: #f57f32; }
    section#home-sales h2 > small {
      font-size: 0.5em;
      font-weight: bold;
      display: block;
      color: #f57f32;
      margin-top: 10px; }
    @media (max-width: 480px) {
      section#home-sales h2 {
        font-size: 24px; } }
  section#home-sales .home-sales-option-wrap {
    display: flex;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    margin: 30px -15px 0;
    flex-wrap: wrap;
    color: #fff; }
    section#home-sales .home-sales-option-wrap > .home-sales-option {
      flex-grow: 1;
      background: #f57f32;
      margin: 15px;
      font-size: 36px;
      line-height: 32px;
      letter-spacing: 0.1em;
      padding: 1em 1em 80px 1em;
      width: 300px;
      height: 280px;
      position: relative; }
      section#home-sales .home-sales-option-wrap > .home-sales-option .option-desc {
        position: absolute;
        top: 118px;
        transform: translateY(-50%);
        left: 0;
        width: 100%; }
      section#home-sales .home-sales-option-wrap > .home-sales-option p {
        margin-bottom: 0.33em; }
      section#home-sales .home-sales-option-wrap > .home-sales-option em {
        font-style: normal;
        font-weight: 300; }
      section#home-sales .home-sales-option-wrap > .home-sales-option a {
        background-color: #444;
        border-bottom: 0;
        line-height: 1;
        padding: 1.5em 6.25em 1.5em 2.5em;
        display: inline-block;
        font-size: 10px;
        letter-spacing: 1.5px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        min-width: 240px;
        color: #fff; }
        section#home-sales .home-sales-option-wrap > .home-sales-option a > span {
          position: absolute;
          top: 0;
          right: 0;
          width: 3.75em;
          height: 100%;
          text-align: center;
          padding: 1.5em 0;
          background-color: #5e5e5e; }
    section#home-sales .home-sales-option-wrap > .home-sales-option-split {
      border: 2px solid #444;
      background: #f57f32;
      font-size: 20px;
      padding: 0.3333em 0.8em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10; }

section#package-benefits {
  margin: 75px 0; }
  section#package-benefits .benefit-item {
    background-color: #eee;
    color: #f57f32;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    max-width: 280px;
    margin: 0 auto; }
    section#package-benefits .benefit-item > img {
      max-width: 100%;
      border-bottom: 5px solid #f57f32; }
    section#package-benefits .benefit-item > p {
      padding: 15px 30px;
      position: relative;
      line-height: 1.3333; }
      section#package-benefits .benefit-item > p:after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        border: 15px solid transparent;
        border-bottom: 15px solid #f57f32;
        width: 0;
        height: 0;
        transform: translateX(-50%); }

aside#selling-calc {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px; }
  aside#selling-calc h3 {
    margin-bottom: 2em; }
  aside#selling-calc #selling-calc-price-title,
  aside#selling-calc #selling-calc-commission-title,
  aside#selling-calc #selling-calc-saving-title,
  aside#selling-calc #selling-calc-fee-title {
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: normal;
    font-weight: bold; }
  aside#selling-calc #selling-calc-price-title,
  aside#selling-calc #selling-calc-commission-title {
    font-size: 10px; }
  aside#selling-calc #selling-calc-saving-title,
  aside#selling-calc #selling-calc-fee-title {
    font-size: 12px; }
  aside#selling-calc #selling-calc-price,
  aside#selling-calc #selling-calc-commission,
  aside#selling-calc #selling-calc-saving,
  aside#selling-calc #selling-calc-fee {
    color: #f57f32;
    font-size: 28px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 0.25em;
    letter-spacing: 2px; }
  aside#selling-calc #selling-calc-saving,
  aside#selling-calc #selling-calc-fee {
    padding: 1em 0;
    background-color: #eee;
    border: 1px solid #444;
    margin-top: 0.5em;
    line-height: 1;
    height: 3em;
    overflow: hidden;
    text-overflow: ellipsis; }
  aside#selling-calc .slider-group {
    margin-bottom: 2em; }
  aside#selling-calc input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 40px;
    background: transparent;
    padding: 0; }
  aside#selling-calc input[type=range]:focus {
    outline: none; }
  aside#selling-calc input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    background-color: #f57f32;
    margin-top: -5px;
    box-shadow: 0 0 5px 1px rgba(68, 68, 68, 0.2); }
  aside#selling-calc input[type=range]::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    background-color: #f57f32;
    box-shadow: 0 0 5px 1px rgba(68, 68, 68, 0.2); }
  aside#selling-calc input[type=range]::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    background-color: #f57f32;
    box-shadow: 0 0 5px 1px rgba(68, 68, 68, 0.2);
    color: transparent; }
  aside#selling-calc input[type=range]::-webkit-slider-runnable-track {
    height: 30px;
    cursor: pointer;
    background-color: #eee;
    border-radius: 15px;
    border: 10px solid #444; }
  aside#selling-calc input[type=range]:focus::-webkit-slider-runnable-track {
    background-color: #eee; }
  aside#selling-calc input[type=range]::-moz-range-track {
    height: 10px;
    cursor: pointer;
    background-color: #eee;
    border-radius: 15px;
    border: 10px solid #444; }
  aside#selling-calc input[type=range]::-ms-track {
    height: 10px;
    cursor: pointer;
    background-color: #eee;
    border-radius: 15px;
    border: 10px solid #444;
    color: transparent;
    color: #eee; }
  aside#selling-calc input[type=range]::-ms-fill-lower {
    background-color: #eee;
    border-radius: 15px; }
  aside#selling-calc input[type=range]:focus::-ms-fill-lower {
    background-color: #eee; }
  aside#selling-calc input[type=range]::-ms-fill-upper {
    background-color: #eee;
    border-radius: 15px; }
  aside#selling-calc input[type=range]:focus::-ms-fill-upper {
    background-color: #eee; }
  aside#selling-calc input[type=range]::-ms-tooltip {
    display: none; }

aside#page-accreditations {
  margin: 40px 0;
  overflow: hidden; }
  @media (max-width: 767px) {
    aside#page-accreditations div.accreditations-wrapper > img {
      max-width: 180px;
      display: block;
      margin: 0 auto 2em; }
      aside#page-accreditations div.accreditations-wrapper > img[src*="tpo-tsi"] {
        max-width: 280px; } }
  @media (min-width: 768px) {
    aside#page-accreditations div.accreditations-wrapper {
      display: flex;
      align-items: center;
      margin: 0 -30px; }
      aside#page-accreditations div.accreditations-wrapper > img {
        flex: 1 1 auto;
        padding: 30px;
        width: 16.67%; }
        aside#page-accreditations div.accreditations-wrapper > img[src*="tpo-tsi"] {
          width: 33.33%; } }

footer#page-footer {
  background-color: #444;
  color: #fff;
  font-size: 14px;
  line-height: 2.14286;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  text-align: left; }
  footer#page-footer a {
    font-weight: bold; }
  footer#page-footer h3 {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 5px;
    margin-top: 0;
    border-bottom: 3px solid #f57f32;
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 1.5em; }
  footer#page-footer > div#page-footer-map > .container-fluid > .row > div {
    padding: 0; }
  footer#page-footer > div#page-footer-map iframe {
    width: 100%;
    height: 600px;
    border: 0; }
    @media (max-width: 767px) {
      footer#page-footer > div#page-footer-map iframe {
        max-height: 80vh; } }
  footer#page-footer p {
    margin-bottom: 30px; }
    footer#page-footer p:last-child {
      margin-bottom: 0; }
    footer#page-footer p.footer-contact {
      text-transform: none; }
  footer#page-footer div.footer-social {
    font-size: 21px; }
    @media (min-width: 768px) {
      footer#page-footer div.footer-social {
        margin-bottom: 122px; } }
    @media (min-width: 992px) {
      footer#page-footer div.footer-social {
        margin-bottom: 102px; } }
  @media (max-width: 767px) {
    footer#page-footer {
      text-align: center; }
      footer#page-footer h3,
      footer#page-footer > div#page-footer-map {
        margin-top: 50px; } }
  @media (min-width: 768px) {
    footer#page-footer {
      height: 600px; }
      footer#page-footer > div#page-footer-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }

footer#page-copyright {
  font-size: 14px;
  background-color: #eee;
  padding: 1.25em 0;
  text-align: left;
  font-weight: normal; }
  footer#page-copyright p {
    margin-bottom: 0; }
  footer#page-copyright a {
    text-decoration: none;
    color: #444;
    border-bottom: 0; }
    footer#page-copyright a:focus, footer#page-copyright a:hover {
      border-bottom: 1px solid #444; }

.search-auto {
  position: relative; }
  .search-auto ul {
    border: 1px solid #444;
    border-top-width: 0;
    list-style-type: none;
    position: absolute;
    width: 100%;
    background: #fff;
    top: 100%;
    padding: 6px 0;
    z-index: 10;
    color: #444;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); }
    .search-auto ul > li {
      cursor: pointer;
      padding: 0 12px;
      text-align: center; }

/* Borrowed from Bootstrap */
.google-map,
#search-map,
#property-map {
  width: 100%;
  height: 500px;
  box-sizing: content-box; }
  .google-map *,
  #search-map *,
  #property-map * {
    box-sizing: content-box; }

#search-map,
#property-map {
  margin-bottom: 40px; }

#google-map-error {
  display: none; }

#google-map-form {
  margin-top: 1em;
  background-color: #f57f32;
  padding: 22px 28px; }
  #google-map-form label {
    color: #fff;
    font-weight: normal; }

#google-map-branch-list > ul {
  list-style-type: none;
  padding: 0; }
  #google-map-branch-list > ul > li > a {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 0;
    color: #f57f32;
    display: block;
    width: 140px;
    position: relative; }
    #google-map-branch-list > ul > li > a:after {
      content: "\f101";
      font-family: FontAwesome;
      position: absolute;
      right: 0;
      color: #6da2d6; }

.google-map-link {
  color: #f3a36f;
  font-weight: 300;
  font-size: 14px; }

#google-map-directions {
  margin-top: 1em; }

.adp-placemark td {
  padding: 4px; }

.adp-marker {
  width: auto !important;
  height: 24px !important; }

.adp-fullwidth td {
  padding: 2px 4px; }

.adp-directions {
  table-layout: fixed;
  width: 100%; }
  .adp-directions td:nth-child(1),
  .adp-directions td:nth-child(2) {
    width: 5% !important; }
  .adp-directions td:nth-child(4) {
    width: 10% !important; }

.adp-substep {
  word-wrap: break-word; }

@media (max-width: 991px) {
  #google-map-from {
    margin-bottom: 10px; } }

/* Borrowed from Bootstrap */
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

#cboxWrapper {
  max-width: none; }

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%; }

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left; }

#cboxContent {
  position: relative;
  border: 1em solid #000;
  border-radius: 1em;
  background: #000;
  overflow: hidden; }

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 2em;
  background: #fff;
  border-radius: 0.5em; }

#cboxTitle {
  margin: 0; }

#cboxLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; }

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer; }

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic; }

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0; }

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background-color: rgba(0, 0, 0, 0.9); }

#colorbox {
  outline: 0; }

.cboxIframe {
  background: #fff; }

#cboxError {
  padding: 50px;
  border: 1px solid #ccc; }

#cboxTitle {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  color: #949494; }

#cboxCurrent {
  position: absolute;
  bottom: 5px;
  left: 50%;
  color: #444;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 13px;
  transform: translateX(-50%); }

#cboxLoadingGraphic {
  font-family: FontAwesome;
  width: 1.28571429em;
  animation: fa-spin 1s infinite steps(8);
  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  font-size: 3em;
  color: #fff; }
  #cboxLoadingGraphic:before {
    content: "\f110"; }

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0; }

#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef; }

#cboxPrevious {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 25px;
  height: 25px; }

#cboxNext {
  position: absolute;
  bottom: 4px;
  left: 27px;
  width: 25px;
  height: 25px; }

#cboxClose {
  position: absolute;
  bottom: 5px;
  right: 4px;
  width: 25px;
  height: 25px;
  font-size: 1.1em; }

#lead-pro-widget iframe {
  margin: -48px 0; }

@keyframes throb {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.08); }
  100% {
    transform: scale(1); } }

@keyframes flyin {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0); } }
