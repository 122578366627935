@import "_variables.scss";

#colorbox, #cboxOverlay, #cboxWrapper
{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
}

#cboxWrapper
{
    max-width: none;
}

#cboxOverlay
{
    position: fixed;
    width: 100%;
    height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft
{
    clear: left;
}

#cboxContent
{
    position: relative;
    border: 1em solid #000;
    border-radius: 1em;
    background: #000;
    overflow: hidden;
}

#cboxLoadedContent
{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 2em;
    background: #fff;
    border-radius: 0.5em;
}

#cboxTitle
{
    margin: 0;
}

#cboxLoadingOverlay
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow
{
    cursor: pointer;
}

.cboxPhoto
{
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic;
}

.cboxIframe
{
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent
{
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay
{
    background-color: rgba(0,0,0,0.9);
}

#colorbox
{
    outline: 0;
}

.cboxIframe
{
    background: #fff;
}

#cboxError
{
    padding: 50px;
    border: 1px solid #ccc;
}

#cboxTitle
{
    position: absolute;
    bottom: 4px;
    left: 0;
    text-align: center;
    width: 100%;
    color: #949494;
}

#cboxCurrent
{
    position: absolute;
    bottom: 5px;
    left: 50%;
    color: $baseText;
    font-family: $altFont;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 13px;
    transform: translateX(-50%);
}

#cboxLoadingGraphic
{
    font-family: FontAwesome;
    width: 1.28571429em;
    animation: fa-spin 1s infinite steps(8);
    position: absolute;
    top: calc(50% - 0.75em);
    left: calc(50% - 0.75em);
    font-size: 3em;
    color: #fff;

    &:before
    {
        content: "\f110";
    }
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose
{
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    width: auto;
    background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active
{
    outline: 0;
}

#cboxSlideshow
{
    position: absolute;
    bottom: 4px;
    right: 30px;
    color: #0092ef;
}

#cboxPrevious
{
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 25px;
    height: 25px;
}

#cboxNext
{
    position: absolute;
    bottom: 4px;
    left: 27px;
    width: 25px;
    height: 25px;
}

#cboxClose
{
    position: absolute;
    bottom: 5px;
    right: 4px;
    width: 25px;
    height: 25px;
    font-size: 1.1em;
}
