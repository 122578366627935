﻿@import "_variables.scss";

.google-map,
#search-map,
#property-map
{
    width: 100%;
    height: 500px;
    box-sizing: content-box;

    *
    {
        box-sizing: content-box;
    }
}

#search-map,
#property-map
{
    margin-bottom: 40px;
}

#google-map-error
{
    display: none;
}

#google-map-form
{
    margin-top: 1em;
    background-color: $accentBase;
    padding: 22px 28px;

    label
    {
        color: #fff;
        font-weight: normal;
    }
}

#google-map-branch-list > ul
{
    list-style-type: none;
    padding: 0;

    > li > a
    {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        padding: 4px 0;
        color: $accentBase;
        display: block;
        width: 140px;
        position: relative;

        &:after
        {
            content: "\f101";
            font-family: FontAwesome;
            position: absolute;
            right: 0;
            color: #6da2d6;
        }
    }
}

.google-map-link
{
    color: $accentLight;
    font-weight: 300;
    font-size: 14px;
}

#google-map-directions
{
    margin-top: 1em;
}

.adp-placemark td
{
    padding: 4px;
}

.adp-marker
{
    width: auto !important;
    height: 24px !important;
}

.adp-fullwidth td
{
    padding: 2px 4px;
}

.adp-directions
{
    table-layout: fixed;
    width: 100%;

    td:nth-child(1),
    td:nth-child(2)
    {
        width: 5% !important;
    }

    td:nth-child(4)
    {
        width: 10% !important;
    }
}

.adp-substep
{
    word-wrap: break-word;
}

@media(max-width: $screen-sm-max)
{
    #google-map-from
    {
        margin-bottom: 10px;
    }
}