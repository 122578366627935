@import "inc/_variables.scss";
//@import "inc/_parallax.scss";

html
{
    font-size: $baseFontSize; // set base for rem
}

div#skiplinks
{
    a,
    a:link,
    a:hover,
    a:visited,
    a:active,
    p
    {
        position: absolute;
        left: 0;
        top: -500px;
        width: 1px;
        height: 1px;
        overflow: hidden;
        color: #ccc;
    }
}

@import "inc/_slider.scss";

div.social > a
{
    background-color: $accentLight;
    color: $baseInverse;
    display: inline-block;
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    margin-right: 0.5em;
    border-radius: 50%;
    transition: background-color ease-in-out 0.1s;

    &:last-child
    {
        margin-right: 0;
    }

    &:hover,
    &:active
    {
        background-color: $accentBase;
    }
}

article#page-content
{
    padding: 48px 0;

    @media(max-width: $screen-sm-max)
    {
        h1:first-child
        {
            margin-top: 1em;
        }
    }
}

header#page-header
{
    text-align: left;
    background-color: $baseText;
    color: $baseInverse;
    padding: 20px 0;
    font-family: $altFont;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    a
    {
        color: $baseInverse;

        &.btn-header
        {
            max-width: 280px;
            margin-bottom: 1em;

            @media(max-width: $screen-xs-max)
            {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.btn-throb
        {
            animation: throb 2s ease-in-out infinite;
        }
    }

    &.page-header-home
    {
        background-image: url(/site/img/header/home.jpg);

        @media(min-width: $screen-md-min) and (max-width: $screen-md-max)
        {
            height: 840px;
        }

        @media(min-width: $screen-lg-min)
        {
            height: 780px;
        }
    }

    &.page-header-packages
    {
        background-image: url(/site/img/header/packages.jpg);
    }

    #page-header-logo
    {
        margin: 0 auto;
        display: block;
    }

    #page-header-phone > a
    {
        border-bottom: 0;
        font-size: 15px;
        letter-spacing: 1.5px;
    }

    .header-social
    {
        margin-bottom: 0.3333em;
        font-size: 18px;
        text-align: center;
    }

    > .container-fluid > .row > div
    {
        margin-bottom: 30px;
    }
}

div#navigation-header
{
    z-index: 200;

    .hamburger
    {
        outline: 0 !important;
    }

    &.expanded
    {
        position: fixed;
        top: 20px;
        right: 0;
        width: 280px;
    }

    @media(min-width:$screen-sm-min)
    {
        max-width: 280px;
        float: right;
    }
}

nav#toggle-navigation
{
    color: $baseInverse;
}

nav#page-navigation
{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    background-color: $baseText;
    box-shadow: 0 0 15px 5px rgba(black, 0.33);
    z-index: 100;
    transform: translateX(315px);
    will-change: transform;
    transition: transform ease-in-out 0.25s;

    &.visible
    {
        transform: translateX(0);
    }

    ul
    {
        list-style: none;
        margin: 130px 0 0 0;
        padding: 0;

        > li
        {
            margin: 15px auto;

            > a
            {
                letter-spacing: 3px;
                border-bottom: 0;
                padding: 6px 0;
                margin: 15px auto;
                position: relative;
                color: $baseInverse;
                text-transform: uppercase;
                display: block;

                &:hover,
                &:focus
                {
                    color: $accentBase;
                    background-color: transparent;
                }

                > span
                {
                    display: inline-block;
                }
            }

            &.external > a
            {
                color: $accentBase;
            }

            &.active > a > span
            {
                border-bottom: 3px solid $accentBase;
                padding: 0 10px;
            }
        }
    }
}

aside#featured-properties
{
    > .bxslider
    {
        display: none;
    }

    .bx-viewport
    {
        margin: 40px 0 20px;
    }

    .bx-pager-item
    {
        display: inline-block;
        margin: 0 4px;

        > a
        {
            text-indent: -999vw;
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            border: 1px solid darken($highlight, 20%);

            &.active
            {
                background-color: darken($highlight, 20%);
            }
        }
    }
}


.featured-item
{
    font-family: $altFont;
    text-transform: uppercase;
    margin-bottom: 2em;
    border-bottom: 0;

    .item-photo
    {
        height: 250px;
        background-size: cover;
        background-position: center center;
        margin-bottom: 1em;
        position: relative;
        overflow: hidden;

        > .item-description
        {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: rgba(black, 0.8);
            color: $baseInverse;
            padding: 20px;
            font-size: 10px;
            letter-spacing: 1px;
            transform: translateY(100%);
            transition: transform ease-in-out 0.25s;
            will-change: transform;
        }


        > div.item-attributes
        {
            background-color: rgba($accentBase, 0.8);
            color: $baseInverse;
            position: absolute;
            top: 20px;
            right: 0;

            > .item-attribute-icons > div
            {
                display: block;
                margin: 6px 12px;
                font-size: 10px;
            }

            i
            {
                display: inline-block;
                font-size: 1.3333em;
                margin-left: 0.3333em;
            }
        }
    }

    h4
    {
        color: $accentBase;
        margin: 0;
        font-size: 18px;
        letter-spacing: 1px;
    }

    p
    {
        margin: 0;
        font-weight: bold;
        color: $baseText;
        letter-spacing: 1px;
    }

    &:hover
    {
        .item-photo > .item-description
        {
            transform: translateY(0);
        }
    }
}

aside#home-parallax
{
    margin: 60px 0;
    height: 400px;
    overflow-y: hidden;

    #home-parallax-photo
    {
        background-image: url(/site/img/home-content.jpg);
        background-size: cover;
        background-position: center center;
        height: 100vh;
        transform: translateY(-50vh);
        will-change: transform;
    }

    @media(max-width: $screen-xs-max)
    {
        height: 300px;
    }
}

aside#property-slider
{
    position: relative;

    @media(min-width: $screen-lg-min)
    {
        &:before
        {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 5;
            left: 0;
            pointer-events: none;
            background: linear-gradient(to right, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.9) 100%);
        }
    }

    .slide
    {
        cursor: pointer;
        min-height: 400px;
        background-size: cover;
        background-position: center center;

        @media(max-width: $screen-xs-max)
        {
            min-height: 280px;
        }
    }

    .bxslider
    {
        display: none;
    }

    .bx-wrapper .bxslider
    {
        display: block;
    }

    .bx-controls-direction
    {
        font-family: FontAwesome;

        > a
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: 0;
            background-color: $baseInverse;
            border-radius: 50%;
            color: $baseText;
            text-align: center;
            width: 1.8em;
            height: 1.8em;
            line-height: 1.7em;
            z-index: 10;

            &.bx-prev
            {
                left: 1em;
            }

            &.bx-next
            {
                right: 1em;
            }
        }
    }
}

nav#search-navigation
{
    background-color: $highlight;

    .navbar-toggle
    {
        border: 0;

        .icon-bar
        {
            background-color: $baseText;
        }
    }

    .navbar
    {
        margin: 0;
        border: 0;

        .navbar-nav
        {
            > li
            {
                > a
                {
                    border-top: 3px solid $highlight;
                    border-bottom: 3px solid $highlight;
                    color: $baseText;
                    font-size: 12px;
                    font-family: $altFont;
                    text-transform: uppercase;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                &.active > a
                {
                    border-top: 3px solid $accentBase;
                    border-bottom: 3px solid $accentBase;
                }

                @media(min-width: $screen-sm-min)
                {
                    border-right: 1px solid darken($highlight, 5%);

                    &:first-child
                    {
                        border-left: 1px solid darken($highlight, 5%);
                    }
                }
            }
        }
    }

    i
    {
        color: $accentBase;
    }

    @media(min-width: $screen-sm-min)
    {
        .navbar-collapse
        {
            padding-left: 0;
            padding-right: 0;
        }

        .navbar-form
        {
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
}

aside.page-search-properties
{
    background-color: $accentBase;
    color: $baseInverse;
    padding: 45px 30px 30px;
    margin-top: 45px;

    label[for=ShowSold]
    {
        margin: 1em 0;
        font-size: 14px;
    }

    @media(max-width: $screen-sm-max)
    {
        .row.form-group
        {
            margin-bottom: -8px;

            > div
            {
                margin-bottom: 10px;
            }
        }
    }
}

.search-list-page
{
    display: none;

    &.active
    {
        display: block;
    }
}

.property-item-wrapper
{
    margin-bottom: 1.5em;
    box-shadow: 0 0 10px 0 rgba($baseText, 0.1);
    border: 1px solid $highlight;
    display: block;
    transition: box-shadow ease-in 0.5s;
    position: relative;
    overflow: hidden;

    &:hover,
    &:active,
    &:focus,
    &:hover:active
    {
        border-bottom-color: $highlight;
        box-shadow: 0 0 8px 2px rgba($baseText, 0.15);
        outline: 0;

        .item-view-more
        {
            border: 0;
            background-color: $accentBase;
            color: $baseInverse;
        }
    }

    div.item-actions
    {
        position: absolute;
        bottom: 0;
        width: 100%;

        @media(max-width: $screen-sm-max)
        {
            display: none;
        }
    }

    .item-view-more
    {
        position: absolute;
        background-color: $highlight;
        width: auto;
        display: inline-block;
        padding-right: 70px;
        padding-left: 30px;
        border: 0;
        bottom: 0;
        right: 0;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;

        > .icon
        {
            width: 40px;
            background-color: $accentBase;
            color: $baseInverse;
        }
    }

    @media(min-width: $screen-md-min)
    {
        div.item-social
        {
            left: 44%;
            position: absolute;
            bottom: 0;
        }
    }
}

div.item-social
{
    font-size: 12px;

    .jssocials-share-link
    {
        border-radius: 50%;
        border: 0;
    }
}

.property-item
{
    .row > div
    {
        padding: 0;
        position: static;
    }

    h3
    {
        font-size: 16px;
        width: 75%;
        margin: 1.5em auto 1em;
    }

    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max)
    {
        .item-details
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    p.item-description
    {
        margin: 0 auto;
        width: 75%;
        font-size: 11px;
        text-transform: uppercase;
        color: $baseText;
        font-family: $altFont;
        font-weight: normal;
        margin-bottom: 60px;

        @media(max-width: $screen-md-max)
        {
            display: none;
        }
    }

    .item-banner
    {
        position: absolute;
        top: 100px;
        left: -2.3333em;
        width: 180px;
        background-color: rgba($accentBase, 0.9);
        color: $baseInverse;
        z-index: 1;
        font-family: $altFont;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        padding: 0.6667em 0;
        transform: rotate(-45deg);
        transform-origin: top left;
    }
}

div.item-attributes
{
    margin: 0 auto 1em;
    color: $baseText;

    > div
    {
        vertical-align: middle;
        padding-right: 20px;
        border-right: 1px solid $highlight;
        margin-right: 20px;

        &.item-attribute-icons
        {
            font-size: 10px;

            > div
            {
                width: 3em;
            }
        }

        &.item-attribute-price
        {
            font-weight: bold;
            letter-spacing: 1px;
        }

        &:last-child
        {
            padding-right: 0;
            border-right: 0;
            margin-right: 0;
        }
    }

    div
    {
        display: inline-block;
    }

    i
    {
        display: block;
        font-size: 1.8em;

        &.fa-bath
        {
            margin-bottom: 0.15em;
        }

        &.vs-sofa
        {
            margin-bottom: 0.075em;
        }
    }
}

.gm-style-iw .property-item
{
    margin-bottom: 0;
    box-shadow: none;
    border: 0;
}

section#property-details
{
    .bullet-row
    {
        font-family: $altFont;
        color: $accentBase;
        font-size: 0.875em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;

        > li
        {
            margin-bottom: 1.5em;
            line-height: 1.25;

            @media(max-width: $screen-sm-max)
            {
                margin-bottom: 0.9em;
            }
        }
    }

    .item-attributes
    {
        margin-bottom: 50px;
    }
}

#toggle-sections
{
    margin-bottom: 3em;
}

section#property-epc .img-thumbnail
{
    margin-bottom: 1.75em;
}



section#home-valuation
{
    margin-bottom: 2.5em;
    animation: flyin 2s ease-in-out;

    h2
    {
        font-size: 28px;
        line-height: 1.3333;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        margin-bottom: 0.5em;
        font-weight: bold;
        margin-top: 0;
    }
}

section#home-sales
{
    margin: 0;
    text-align: center;
    font-family: $altFont;

    h2
    {
        margin-top: 30px;
        font-size: 28px;
        line-height: 1.3333;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        margin-bottom: 0.5em;
        font-weight: bold;
        color: $accentBase;

        > small
        {
            font-size: 0.5em;
            font-weight: bold;
            display: block;
            color: $accentBase;
            margin-top: 10px;
        }

        @media(max-width: $screen-xs-min)
        {
            font-size: 24px;
        }
    }

    .home-sales-option-wrap
    {
        display: flex;
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        margin: 30px -15px 0;
        flex-wrap: wrap;
        color: $baseInverse;

        > .home-sales-option
        {
            flex-grow: 1;
            background: $accentBase;
            margin: 15px;
            font-size: 36px;
            line-height: 32px;
            letter-spacing: 0.1em;
            padding: 1em 1em 80px 1em;
            width: 300px;
            height: 280px;
            position: relative;

            .option-desc
            {
                position: absolute;
                top: 118px;
                transform: translateY(-50%);
                left: 0;
                width: 100%;
            }

            p
            {
                margin-bottom: 0.33em;
            }

            em
            {
                font-style: normal;
                font-weight: 300;
            }

            a
            {
                background-color: $baseText;
                border-bottom: 0;
                line-height: 1;
                padding: 1.5em 6.25em 1.5em 2.5em;
                display: inline-block;
                font-size: 10px;
                letter-spacing: 1.5px;
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                width: 60%;
                min-width: 240px;
                color: $baseInverse;

                > span
                {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 3.75em;
                    height: 100%;
                    text-align: center;
                    padding: 1.5em 0;
                    background-color: lighten($baseText, 10%);
                }
            }
        }

        > .home-sales-option-split
        {
            border: 2px solid $baseText;
            background: $accentBase;
            font-size: 20px;
            padding: 0.3333em 0.8em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
    }
}

section#package-benefits
{
    margin: 75px 0;

    .benefit-item
    {
        background-color: $highlight;
        color: $accentBase;
        text-align: center;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
        max-width: 280px;
        margin: 0 auto;

        > img
        {
            max-width: 100%;
            border-bottom: 5px solid $accentBase;
        }

        > p
        {
            padding: 15px 30px;
            position: relative;
            line-height: 1.3333;

            &:after
            {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                border: 15px solid transparent;
                border-bottom: 15px solid #f57f32;
                width: 0;
                height: 0;
                transform: translateX(-50%);
            }
        }
    }
}

aside#selling-calc
{
    font-family: $altFont;
    letter-spacing: 1px;

    h3
    {
        margin-bottom: 2em;
    }

    #selling-calc-price-title,
    #selling-calc-commission-title,
    #selling-calc-saving-title,
    #selling-calc-fee-title
    {
        text-transform: uppercase;
        line-height: 1.2;
        font-weight: normal;
        font-weight: bold;
    }

    #selling-calc-price-title,
    #selling-calc-commission-title
    {
        font-size: 10px;
    }

    #selling-calc-saving-title,
    #selling-calc-fee-title
    {
        font-size: 12px;
    }

    #selling-calc-price,
    #selling-calc-commission,
    #selling-calc-saving,
    #selling-calc-fee
    {
        color: $accentBase;
        font-size: 28px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 0.25em;
        letter-spacing: 2px;
    }


    #selling-calc-saving,
    #selling-calc-fee
    {
        padding: 1em 0;
        background-color: $highlight;
        border: 1px solid $baseText;
        margin-top: 0.5em;
        line-height: 1;
        height: 3em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .slider-group
    {
        margin-bottom: 2em;
    }

    input[type=range]
    {
        -webkit-appearance: none;
        width: 100%;
        height: 40px;
        background: transparent;
        padding: 0;
    }

        input[type=range]:focus
        {
            outline: none;
        }

        input[type=range]::-webkit-slider-thumb
        {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border: 0;
            border-radius: 50%;
            cursor: pointer;
            background-color: $accentBase;
            margin-top: -5px;
            box-shadow: 0 0 5px 1px rgba($baseText, 0.2);
        }

        input[type=range]::-moz-range-thumb
        {
            height: 20px;
            width: 20px;
            border: 0;
            border-radius: 50%;
            cursor: pointer;
            background-color: $accentBase;
            box-shadow: 0 0 5px 1px rgba($baseText, 0.2);
        }

        input[type=range]::-ms-thumb
        {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 0;
            cursor: pointer;
            background-color: $accentBase;
            box-shadow: 0 0 5px 1px rgba($baseText, 0.2);
            color: transparent;
        }

        input[type=range]::-webkit-slider-runnable-track
        {
            height: 30px;
            cursor: pointer;
            background-color: $highlight;
            border-radius: 15px;
            border: 10px solid $baseText;
        }

        input[type=range]:focus::-webkit-slider-runnable-track
        {
            background-color: $highlight;
        }

        input[type=range]::-moz-range-track
        {
            height: 10px;
            cursor: pointer;
            background-color: $highlight;
            border-radius: 15px;
            border: 10px solid $baseText;
        }

        input[type=range]::-ms-track
        {
            height: 10px;
            cursor: pointer;
            background-color: $highlight;
            border-radius: 15px;
            border: 10px solid $baseText;
            color: transparent;
            color: $highlight;
        }

        input[type=range]::-ms-fill-lower
        {
            background-color: $highlight;
            border-radius: 15px;
        }

        input[type=range]:focus::-ms-fill-lower
        {
            background-color: $highlight;
        }

        input[type=range]::-ms-fill-upper
        {
            background-color: $highlight;
            border-radius: 15px;
        }

        input[type=range]:focus::-ms-fill-upper
        {
            background-color: $highlight;
        }

        input[type=range]::-ms-tooltip
        {
            display: none;
        }
}

aside#page-accreditations
{
    margin: 40px 0;
    overflow: hidden;

    @media(max-width: $screen-xs-max)
    {
        div.accreditations-wrapper
        {

            > img
            {
                max-width: 180px;
                display: block;
                margin: 0 auto 2em;

                &[src*="tpo-tsi"]
                {
                    max-width: 280px;
                }
            }
        }
    }

    @media(min-width: $screen-sm-min)
    {
        div.accreditations-wrapper
        {
            display: flex;
            align-items: center;
            margin: 0 -30px;

            > img
            {
                flex: 1 1 auto;
                padding: 30px;
                width: 16.67%;

                &[src*="tpo-tsi"]
                {
                    width: 33.33%;
                }
            }
        }
    }
}

footer#page-footer
{
    background-color: $baseText;
    color: $baseInverse;
    font-size: 14px;
    line-height: (30px / 14px) * 1;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    text-align: left;

    a
    {
        font-weight: bold;
    }

    h3
    {
        font-size: 18px;
        line-height: 1;
        letter-spacing: 5px;
        margin-top: 0;
        border-bottom: 3px solid $accentBase;
        display: inline-block;
        padding-bottom: 8px;
        margin-bottom: 1.5em;
    }

    > div#page-footer-map
    {
        > .container-fluid > .row > div
        {
            padding: 0;
        }

        iframe
        {
            width: 100%;
            height: 600px;
            border: 0;

            @media(max-width: $screen-xs-max)
            {
                max-height: 80vh;
            }
        }
    }

    p
    {
        margin-bottom: 30px;

        &:last-child
        {
            margin-bottom: 0;
        }

        &.footer-contact
        {
            text-transform: none;
            // margin-bottom: 1px; // hack to get around sub-pixel positioning
        }
    }

    div.footer-social
    {
        font-size: 21px;

        @media(min-width: $screen-sm-min)
        {
            margin-bottom: 122px;
        }

        @media (min-width: $screen-md-min)
        {
            margin-bottom: 102px;
        }
    }

    @media(max-width: $screen-xs-max)
    {
        text-align: center;

        h3,
        > div#page-footer-map
        {
            margin-top: 50px;
        }
    }

    @media(min-width: $screen-sm-min)
    {
        height: 600px;

        > div#page-footer-content
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

footer#page-copyright
{
    font-size: 14px;
    background-color: $highlight;
    padding: 1.25em 0;
    text-align: left;
    font-weight: normal;

    p
    {
        margin-bottom: 0;
    }

    a
    {
        text-decoration: none;
        color: $baseText;
        border-bottom: 0;

        &:focus,
        &:hover
        {
            border-bottom: 1px solid $baseText;
        }
    }
}

.search-auto
{
    position: relative;

    ul
    {
        border: 1px solid $baseText;
        border-top-width: 0;
        list-style-type: none;
        position: absolute;
        width: 100%;
        background: #fff;
        top: 100%;
        padding: 6px 0;
        z-index: 10;
        color: $baseText;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.5);

        > li
        {
            cursor: pointer;
            padding: 0 12px;
            text-align: center;
        }
    }
}

@import "inc/_maps.scss";
@import "inc/_colorbox.scss";

#lead-pro-widget iframe
{
    margin: -48px 0;
}

@keyframes throb
{
    0%
    {
        transform: scale(1);
    }

    50%
    {
        transform: scale(1.08);
    }

    100%
    {
        transform: scale(1);
    }
}

@keyframes flyin
{
    0%
    {
        transform: translateX(-100vw);
    }

    100%
    {
        transform: translateX(0);
    }
}
